import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
//import { ProxyService } from '../../shared/providers/proxy/proxy.service';

import { ActivatedRoute } from '@angular/router';

import { GlobalService } from 'src/app/shared/services/global.service';
import { ChannelService } from 'src/app/shared/services/channel.service';
import { CausalService } from 'src/app/shared/services/causal.service';
import { SubcausalService } from 'src/app/shared/services/subcausal.service';
import { StateService } from 'src/app/shared/services/state.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { ResponsibleEntityService } from 'src/app/shared/services/responsible-entity.service';
import { PriorityService } from 'src/app/shared/services/priority.service';
import { TicketService } from 'src/app/shared/services/ticket.service';
import { MotivoService } from 'src/app/shared/services/motivo.service';

import { Ticket } from 'src/app/shared/interfaces/ticket';
import { Client } from 'src/app/shared/interfaces/client';
import { Consultants } from 'src/app/shared/interfaces/consultants';
import { ConsultantService } from 'src/app/shared/services/consultant.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalAlert } from 'src/app/app.component';

import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DatePipe } from '@angular/common';
import { differenceInDays } from 'date-fns';

// us-east-1:239fb350-bacc-4034-a7cc-52838d354971
var arrayAreas=[];
@Component({
  selector: 'app-ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.scss']
})

export class TicketFormComponent implements OnInit {

  //public listaAreas = {};
  dropdownList = [];
  public selectedItems = [];
  dropdownSettings:IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };


  private number = null;
  public clienteNombre = '';
  public clienteTipodoc = '';
  public clienteDoc = '';

  public channels = [];
  public causals = [];
  public subcausals = [];
  public states = [];
  public responsibleEntities = [];
  public priorities = [];
  public consultants = [];
  public consultants_fil = [];
  public clients = [];
  public motivos = [];
  public client = [];
  
  public implicatedUsers = {};
  public formTicket: FormGroup;
  
  private formDataTicket: FormData = new FormData();
  public uploadDocumentName = [];
  
  public fests= [];
  public fechaVence= new Date();
  public selectpri='';
  public diasHab=0;
  public selectSub='';
  public coljuegos= false;
  public entSelected= '';
  public consultantsSac= false;
  public clicked= false;
  public areasClick= false;
  public fechainclick= false;
  public fechaIncidente= '';
  public cuponInput= false;
  public campanaNombre= false;
  public betId= false;
  public idRonda= false;
  public nfecha_incidente='';
  public radicado='';
  public subradicado='';
  public cupon='';
  public camp_nombre='';
  public bet_id='';
  public id_ronda='';
  public cuponclick= false;
  public verifCupon= false;
  public selImplicados= '';
  public motivo= '0';
  public motivoSubc= false;
  public motivoAreas= false;
  public motivoMostrar= false;
  public verifMotivo= false;
  public motivoClick= false;
  public motivoSelected= false;
  public diasCol = '';
  public prioridadVerif= false;
  public selectedImplicatedUser= '';



  constructor(
    public router: Router,
    public global: GlobalService,
    private channelService: ChannelService,
    private causalService: CausalService,
    private subcausalService: SubcausalService,
    private stateService: StateService,
    private responsibleEntitieService: ResponsibleEntityService,
    private priorityService: PriorityService,
    private motivoService: MotivoService,
    private formBuilder: FormBuilder,
    private ticketService: TicketService,
    private consultantService: ConsultantService,
    private clientService: ClientService,
    private activatedRoute: ActivatedRoute,
    private _modalService: NgbModal,
    public datepipe: DatePipe,
    //private proxy: ProxyService
  ) {
    this.formTicket = this.formBuilder.group({
      client: [''],
      //client: [null, Validators.required],
      channel: ['', Validators.required],
      causal: ['', Validators.required],
      subcausal: ['', Validators.required],
      state: ['', Validators.required],
      responsible_entity: ['', Validators.required],
      priority: [''],
      observations: [''],
      radicado: [''],
      subradicado: [''],
      areas_asignadas: ['', Validators.required],
      fecha_incidente: ['', Validators.required],
      cupon: [''],
      camp_nombre: [''],
      bet_id: [''],
      id_ronda: [''],
      lista_implicados: ['', Validators.required],
      motivo: [''],
      dias_coljuegos: [''],
      
    });
  }
  

  ngOnInit() {


    this.dropdownList = [
      { item_id: 1, item_text: 'Operaciones BetPlay' },
      { item_id: 2, item_text: 'BI BetPlay' },
      { item_id: 3, item_text: 'Marketing BetPlay' },
      { item_id: 4, item_text: 'SAC BetPlay' },
      { item_id: 5, item_text: 'Experiencia al cliente BetPlay' },
      { item_id: 6, item_text: 'Tecnología CEM' },
      { item_id: 7, item_text: 'Control de operaciones OnLine BetPlay' },
      { item_id: 8, item_text: 'Retail BetPlay' },
      { item_id: 9, item_text: 'Gerencia OnLine' },
      { item_id: 10, item_text: 'Gerencia Jurídica' },
      { item_id: 11, item_text: 'Gerencia Financiera' },
      { item_id: 12, item_text: 'Gerencia Auditoría' },
      { item_id: 13, item_text: 'Gerencia Cumplimiento' },
    ];
 
    this.activatedRoute.paramMap.subscribe(params => {
      this.number = params.get('number');
      //console.log("p"+JSON.stringify(params));

    });
    //console.log(this.number);
    this.clientService.getCliente(this.number).then((response: any) => {
      this.client = response.data;
      //console.log(this.client); 
      this.clienteNombre = this.client['PrimerNombre']+' '+this.client['SegundoNombre']+' '+this.client['PrimerApellido']+' '+this.client['SegundoApellido'];
      this.clienteTipodoc = this.client['TipoDocumento'];
      this.clienteDoc = this.client['Identificacion'];
      //console.log(this.clienteNombre);
    });
    this.channels = this.channelService.getList();
    this.causals = this.causalService.getList();
    this.subcausalService.getList().then((response: any) => {
      this.subcausals = response.data.datos;
      this.fests= response.data.fest;
      //console.log(response.data.fest);
    });
    this.states = this.stateService.getList();
    this.responsibleEntities = this.responsibleEntitieService.getList();
    this.responsibleEntities.forEach(element => {
      if(element.id==4){
        this.entSelected = element.id;
      }
    });
    
    //console.log(this.implicatedUsers);
  

    this.priorities = this.priorityService.getList();

    this.motivos = this.motivoService.getList();

    this.consultantService.getList().then((response: any) => {
      this.consultants = response.data;
      console.log(this.consultants);
      this.consultants.forEach(element => {
        if(element.id==this.global.USER_DATA.id && element.gerencia_id==1){
          this.consultantsSac=true;
        }
      });
      //console.log(this.consultantsSac);
    });

    
    
    /* this.clientService.getList().then((response: any) => {
      this.clients = response.data;
      //console.log(this.clients);

      this.clients.forEach(element => {
        if(element.id == this.number){
          this.clienteNombre = element.PrimerNombre+' '+element.SegundoNombre+' '+element.PrimerApellido+' '+element.SegundoApellido;
          this.clienteTipodoc = element.TipoDocumento;
          this.clienteDoc = element.Identificacion;

          //console.log(element);
        }
      });
      //console.log(this.clients);
    }); */



  }

  onItemSelect(item: any) {
    //console.log(this.selectedItems);
    //console.log(item);
    var unaArea=[];
    this.selectedItems.forEach(element => {unaArea.push(element.item_text.toLowerCase())});
    arrayAreas=unaArea;
    // Filtra los consultores según las áreas seleccionadas
    this.consultants_fil = this.consultants.filter((v) => {
      const areaNombre = v.gerencias.nombre.toLowerCase();
      const areaArea = v.gerencias.area.toLowerCase();

      // Verifica si el nombre del área o el área coinciden con las áreas seleccionadas
      if (arrayAreas.includes(areaNombre) || arrayAreas.includes(areaArea)) {
          return true;
      }

      return false;
    });
  }

  onSelectAll(items: any) {
    //console.log(items);
    var unaArea=[];
    items.forEach(element => {unaArea.push(element.item_text.toLowerCase())});
    arrayAreas=unaArea;
    
  }

  motivoAreaCheck(){
    this.motivoSelected =false;
    this.motivoAreas = false;
    //console.log(this.selectedItems);
    var listaArea=[];
    this.selectedItems.forEach(element => {listaArea.push(element.item_id)});
    console.log(listaArea);
    listaArea.forEach(element => {
      if (element == 13){
        //console.log(element);
        this.motivoAreas = true;
        this.motivoSelected =true;
      }
    });
    if (this.motivoSelected == false){
      this.motivo='0';
    }

  }

  motivocheck(){

    //console.log(this.motivo);
    this.motivoMostrar=false
    if(this.motivoAreas == true && this.motivoSubc == true){
      this.motivoMostrar= true;
    }
  }


/*   addImplicatedUser(user) {
    //console.log(user);
    var cons= '';
    this.consultants.forEach((value, index) => {
      if(value.correo==user){
        cons=JSON.stringify(this.consultants[index]);
      }
    });
    user = JSON.parse(cons);
    this.implicatedUsers[user.id] = user;
    //console.log(this.implicatedUsers);
    this.valImplicados()
    
  } */

    addImplicatedUser() {
      console.log(this.selectedImplicatedUser);

      const selectedConsultant = this.consultants.find(consultant => consultant.id === +this.selectedImplicatedUser);
      if (selectedConsultant && !this.implicatedUsers[selectedConsultant.id]) {
        this.implicatedUsers[selectedConsultant.id] = selectedConsultant;
      }
      this.valImplicados();
      this.selectedImplicatedUser = '';  // Limpiar la selección después de agregar */
    }
    

  deleteImplicatedUser(sub) {
    delete this.implicatedUsers[sub];
    //console.log(this.implicatedUsers);
    this.valImplicados()
    
  }

  valImplicados(){
    this.selImplicados=JSON.stringify(this.implicatedUsers);
    //console.log(this.selImplicados);
    if(this.selImplicados=='{}'){
      this.selImplicados='';
    }
    
  }

  // deleteAdjunto(id) {
  //   delete this.uploadDocumentName[id];
  //   this.formDataTicket.delete('documento_'+id);
  // }

  // public documentChangeEvent(fileInput: any) {
    
  //   const file_list: FileList = fileInput.target.files;

  //   if (file_list != undefined && file_list.length > 0) {
        
  //       const file: File = file_list[0];

  //       if (file.size > 1999999) {
  //         const temp=this._modalService.open(NgbdModalAlert);
  //         temp.componentInstance.text="El archivo no puede superar los 2MB";
  //       }else{
  //         this.formDataTicket.append('documento_'+this.uploadDocumentName.length, file, file.name);
  //         this.uploadDocumentName.push(file.name);
  //         console.log(file.name);
  //       }
      

  //   }
  // }

  public documentChangeEvent(fileInput: any) {
    const file_list: FileList = fileInput.target.files;
  
    if (file_list && file_list.length > 0) {
      for (let i = 0; i < file_list.length; i++) {
        const file: File = file_list[i];
  
        if (file.size > 5000001) {
          const temp = this._modalService.open(NgbdModalAlert);
          temp.componentInstance.text = "El archivo no puede superar los 2MB";
        } else {
          this.formDataTicket.append('documento_' + this.uploadDocumentName.length, file, file.name);
          this.uploadDocumentName.push(file.name);
          console.log(file.name);
        }
      }
    }
  }
  
  deleteAdjunto(index: number) {
    this.uploadDocumentName.splice(index, 1);
    this.formDataTicket.delete('documento_' + index);
  }
  

  createTicket() {

    let observaciones = this.formTicket.get('observations').value;
    let textoConSalto = observaciones.replace(/\n/g, '<br>');
    //console.log(textoConSalto);

    this.nfecha_incidente=this.datepipe.transform(this.fechaIncidente, 'yyyy-MM-dd HH:mm:ss');
    
    const ticketForm = this.formTicket.value;
    
    this.formDataTicket.append('cliente_id', this.number);
    this.formDataTicket.append('canal_id', ticketForm.channel);
    this.formDataTicket.append('subcausal_id', ticketForm.subcausal);
    this.formDataTicket.append('estado_id', ticketForm.state);
    this.formDataTicket.append('radicado', ticketForm.radicado);
    this.formDataTicket.append('subradicado', ticketForm.subradicado);
    this.formDataTicket.append('entidad_responsable_id', ticketForm.responsible_entity);
    this.formDataTicket.append('prioridad', this.selectpri);
    this.formDataTicket.append('observaciones', textoConSalto);
    this.formDataTicket.append('implicados', JSON.stringify(this.implicatedUsers));
    this.formDataTicket.append('area_asignada', '1');
    this.formDataTicket.append('areas_asignadas', JSON.stringify(this.selectedItems));
    this.formDataTicket.append('fecha_incidente', this.nfecha_incidente);
    this.formDataTicket.append('cupon', ticketForm.cupon);
    this.formDataTicket.append('campana_nombre', ticketForm.camp_nombre);
    this.formDataTicket.append('bet_id', ticketForm.bet_id);
    this.formDataTicket.append('id_ronda', ticketForm.id_ronda);
    this.formDataTicket.append('motivo', ticketForm.motivo);
    this.formDataTicket.append('dias_coljuegos', this.diasCol);

    this.ticketService.create(this.formDataTicket).then((response: any) => {
      const temp=this._modalService.open(NgbdModalAlert);
      temp.componentInstance.text=response.message;
      //alert(response.message);

      if (response.status == 'success') {
        this.router.navigateByUrl('/ticket/' + response.data.id);
      }
    });



  }

  public searchClient = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    map(term => term === '' ? []
      : this.clients.filter(
        v => (
          v.Identificacion  + ' '
          + v.PrimerNombre + ' '
          + v.SegundoNombre + ' '
          + v.PrimerApellido + ' '
          + v.SegundoApellido + ' '
          + v.AccountCode + ' '
          + v.ticketsId
        ).toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
    )
  ) 


  public formatterClient = (x: Client) => x.PrimerNombre + ' ' + x.SegundoNombre + ' ' + x.PrimerApellido + ' ' + x.SegundoApellido;





    public searchConsultants = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => term === '' ? []
        : this.consultants.filter(
          function (v){
            
            //console.log(arrayAreas);
            //console.log(v.gerencias.nombre);
            //console.log(v.gerencias.area);

            if(!arrayAreas.includes(v.gerencias.nombre.toLowerCase())){

              if(!arrayAreas.includes(v.gerencias.area.toLowerCase())){

                return false;
              }
            }
            return ( v.nombre + ' ' + v.apellido + ' ' + v.correo ).toLowerCase().indexOf(term.toLowerCase()) > -1;
          }).slice(0, 10)
      )
    ) 
  
    public formatterConsultants = (x: Consultants) => x.correo;

    esFindeSemana(f: Date){

      if(f.getDay()==6 || f.getDay()==0){
        return true;
      }
      return false;
    }

    getStringDate(current_datetime){
      return current_datetime.getFullYear()+ "-" + (current_datetime.getMonth() + 1).toString().padStart(2, 0) + "-" + current_datetime.getDate().toString().padStart(2, 0) ;
    }

    esFestivo(fecha: Date){
      if(this.fests.includes(this.getStringDate(fecha))){
        return true;
      }
    }

    resetSubcausal(){
      this.coljuegos= false;
      this.cuponInput= false;
      this.campanaNombre= false;
      this.betId= false;
      this.idRonda= false;
      this.motivoSubc= false;
      this.motivoMostrar= false;
      this.selectSub="";
      if (this.selectSub==''){
        this.selectpri='';
      }
    }

  
    
    subcausalPri(){
      this.subcausals.forEach(subc => {
        if(subc.id==this.selectSub){
          this.selectpri=subc.prioridad;
          this.priorities.forEach(element => {
            if(element.id==this.selectpri){
              this.diasHab= element.vence;
              var d = new Date();
              var dia = new Date(d.setDate(d.getDate()));
              console.log(this.selectpri)
              var cont=0;
              while (cont<=element.vence) {
                if(this.esFindeSemana(dia) || this.esFestivo(dia)){
                }else{
                  cont++;
                }
                dia.setDate(dia.getDate() + 1);
              }
              dia.setDate(dia.getDate() - 1);
              this.fechaVence=dia;
              
            } 
          });
       }
      });

      //console.log("dias "+this.diasHab)
    }

    

  
    subcFiltros(){
      console.log(this.selectSub);
      this.subcausals.forEach(subc => {
        if(subc.id==this.selectSub){
          if(subc.id==37){
            this.coljuegos= true;
            this.cuponInput= false;
            this.betId= false;
            this.campanaNombre= false;
            this.idRonda= false;
            this.motivoSubc= false;
          }else if(subc.id==32){
            this.coljuegos= false;
            this.cuponInput= true;
            this.campanaNombre= false;
            this.betId= false;
            this.idRonda= false;
            this.motivoSubc= false;
          }else if(subc.id==36){
            this.coljuegos= false;
            this.cuponInput= false;
            this.campanaNombre= true;
            this.betId= false;
            this.idRonda= false;
            this.motivoSubc= false;
          }else if(subc.id==40){
            this.coljuegos= false;
            this.cuponInput= false;
            this.campanaNombre= false;
            this.betId= true;
            this.idRonda= false;
            this.motivoSubc= false;
          }else if(subc.id==24){
            this.coljuegos= false;
            this.cuponInput= false;
            this.campanaNombre= false;
            this.betId= false;
            this.idRonda= true;
            this.motivoSubc= false;
          }else if(subc.id==8){
            this.coljuegos= false;
            this.cuponInput= false;
            this.campanaNombre= false;
            this.betId= false;
            this.idRonda= false;
            this.motivoSubc= true;
          }else{
            this.coljuegos= false;
            this.cuponInput= false;
            this.campanaNombre= false;
            this.betId= false;
            this.idRonda= false;
            this.motivoSubc= false;
          }
        }
      });
    }

    resetCampos(){
      this.radicado = ''; 
      this.subradicado='';
      this.cupon='';
      this.camp_nombre='';
      this.bet_id='';
      this.id_ronda='';  
      this.motivo= '0'; 
      //console.log(this.motivo)
    }

    cuponCheck(){
      if(this.cuponInput && (this.cupon=='' || this.cupon==null)){
        this.verifCupon= false;
        //console.log(this.verifCupon);
        //console.log(this.cupon);
      }else{
        this.verifCupon= true;
        //console.log(this.verifCupon);
        //console.log(this.cupon);
      }
    }

    motivoObligatorio(){
      if(this.motivoMostrar && (this.motivo=='0' || this.motivo==null)){
        this.verifMotivo= false;
        //console.log(this.verifMotivo);
        //console.log(this.motivo);
      }else{
        this.verifMotivo= true;
        //console.log(this.verifMotivo);
        //console.log(this.motivo);
      }
    }

    diasColjuegos(){
      if (this.coljuegos && this.fechaIncidente != ''){
        var d = new Date();
        var dia = new Date(d.setDate(d.getDate()));
        const hoy = new Date(dia);
        var fechain = new Date(this.fechaIncidente);
        const cantidadDias = differenceInDays(hoy, fechain);
        console.log("dias"+cantidadDias); 

        var cont=0;
        var diashcol=0;
        while (cont<cantidadDias) {
          dia.setDate(dia.getDate() - 1);
          if(this.esFindeSemana(dia) || this.esFestivo(dia)){
            //console.log("fiesta"+dia)
          }else{
            diashcol++;
          }
          cont++;
        }
        this.diasCol = diashcol.toString();
        
      }else{
        this.diasCol = '';
      }
      console.log(this.diasCol)
    }

    /* getPriorityName(id: string): string {
      const priority = this.priorities.find(p => p.id === id);
      return priority ? priority.name : '';
    } */

    diasHabilesPri(){
      console.log(this.selectpri);
      this.priorities.forEach(element => {
        if(element.id==this.selectpri){
          this.diasHab= element.vence;
          var d = new Date();
          var dia = new Date(d.setDate(d.getDate()));
          //console.log(dia)
          var cont=0;
          while (cont<=element.vence) {
            if(this.esFindeSemana(dia) || this.esFestivo(dia)){
            }else{
              cont++;
            }
            dia.setDate(dia.getDate() + 1);
          }
          dia.setDate(dia.getDate() - 1);
          this.fechaVence=dia;
          
        } 
      });

    }

    verificarPrioridad(){
      if(this.selectpri==''){
        this.prioridadVerif= false;
      }else{
        this.prioridadVerif= true;
      }
    }
    
    

    


  




    


}
